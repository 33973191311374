/*
 * causes.less
 * -----------------------------------------------
*/
.course-single-item .course-thumb {
	position: relative;
}
.course-single-item .course-top-meta {
	position: absolute;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.6);
	padding: 4px 20px;
	border-radius: 0 4px 0 0;
}
.course-single-item .course-top-meta li {
	color: #fff;
	font-size: 13px;
}
.course-single-item .course-top-meta ul li i {
	vertical-align: middle;
	margin-right: 8px;
}
.course-single-item .course-details {
	position: relative;
}
.course-single-item .author-thumb {
	position: absolute;
	top: 15px;
	right: 15px;
}
.course-single-item .author-thumb img {
	width: 42px;
}
.course-single-item .course-meta {
	padding: 15px 20px;
	border-top: 1px solid #eee;
	position: relative;
}
.course-single-item .course-meta ul li {
	font-size: 14px;
	vertical-align: middle;
	font-weight: 400;
}
.course-single-item .course-meta ul li i {
	vertical-align: middle;
	margin-top: -4px;
	margin-right: 8px;
}
.course-single-item .course-meta .course-tag {
	position: absolute;
	top: 15px;
	right: 15px;
}
.course-single-item .course-meta .course-tag h5 {
	margin: 0;
	padding: 5px 18px;
	border-radius: 0px;
	color: #fff;
	font-size: 12px;
}
.course-info-title {
  color: @black-555;
  float: left;
  font-weight: 600;
  width: 25%;
}
.course-info-list > li {
  clear: both;
  margin-top: 15px;
}
.single-course-thumb {
	position: relative;
}
.single-course-thumb .overlay-shade {
  border-radius: 8px;
  content: "";
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  .transition(all 150ms ease-in-out 0s);
}
.single-course-thumb .course-info {
  color: #fff;
  left: 0;
  margin: -32px auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  opacity: 0;
  .transition(all 150ms ease-in-out 0s);
}
.single-course-thumb:hover .overlay-shade {
	opacity: 0.75;
}
.single-course-thumb:hover .course-info {
	opacity: 1;
}
.order-btn {
  left: 15px;
  position: absolute;
  top: 15px;
}