/*
 * inner-header-title.less
 * -----------------------------------------------
*/
.inner-header {
	.title {
		font-size: 36px;
		text-transform: uppercase;
		margin-top: 0;
	}
}