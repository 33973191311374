/*
 * team.less
 * -----------------------------------------------
*/
.team-members .team-bottom-part {
	min-height: 225px;
}
.team-members .team-thumb {
	margin-right: 20px;
}
.team-members .styled-icons li a:hover i {
	color: #fff;
}
@media only screen and (max-width : 1024px) {
	.team-pull-none,
	.pull-left.flip.team-pull-none,
	.pull-right.flip.team-pull-none {
		float: none !important;
		clear: both;
		margin-right: 0;
	}
}
@media (max-width:1024px) {
	.t-mr-0 {
		margin-right: 0 !important;
	}
}