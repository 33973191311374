/*
 * event.less
 * -----------------------------------------------
*/
.event-block {
    margin: 4px 0;
    position: relative;
    .event-date {
      padding: 6px 18px;
      position: absolute;
      top: 24px;
      .transition(all 200ms ease-in-out 0s);
    }
    .event-meta {
      background: #f7f6f2 none repeat scroll 0 0;
      display: inline-block;
      margin-left: 40px;
      padding: 12px 16px 8px 14px;
      z-index: -1;
    }
    &:hover {
        .event-date {
          top: 16px;
        }
    }
}
.event-small .event-date {
  float: left;
  margin-right: 20px;
  padding: 0 16px 5px 20px;
  text-align: center;
}
/* -------- event Style ---------- */
.event-grid .thumb {
    position: relative;
    overflow: hidden;
}
.event-grid .thumb .entry-date {
	bottom: 24px;
	position: absolute;
	right: 0;
	padding: 9px 12px 9px 12px;
    .transition(all 0.3s ease 0s);
}
.event-grid-details ul li i {
	width: 15px;
	height: 15px;
}
/* -------- event Style ---------- */
.event-list .thumb {
    position: relative;
    overflow: hidden;
}
.event-list .thumb img {
    transition: all 500ms ease 0s;
}
.event-list:hover .thumb img {
    transform: scale(1.1);
}
.event-list .thumb .overlay-donate-now {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: -50px;
    transition: all 400ms ease 0s;
}
.event-list:hover .thumb .overlay-donate-now {
    top: 10%;
}
.event-list .thumb .entry-date {
    bottom: 0;
    position: absolute;
    right: -44px;
    .transition(all 0.3s ease 0s);
}
.event-list:hover .thumb .entry-date {
    right: 0;
}