/*
 *	boxed-layout.less
 * -----------------------------------------------
*/
body {
	&.container-1340px {
		.container {
			width: 1340px;
		}
	}
	&.container-970px {
		.container {
			width: 970px;
		}
	}
}
.boxed-layout {
	background-color: @black-444;
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 15px;
	padding-right: 15px;

	&.layer-overlay:before {
		z-index: -1;
	}
	
	#wrapper{
		margin: 0 auto;
		@media (min-width:1200px){
			width: calc( 1140px + 30px );
		}
	}
	.container {
		.container {
			width: 100%;
		}
		@media (min-width:1200px){
			width: 1140px;
		}
	}

	&.container-1340px {
		#wrapper {
			@media (min-width:1340px){
				width: calc( 1300px + 30px );
			}
		}
		.container,
		.container-fluid {
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;
			.container,
			.container-fluid {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&.container-970px {
		#wrapper {
			@media (min-width:992px){
				width: calc( 940px + 30px );
			}
		}
		.container,
		.container-fluid {
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;
			.container,
			.container-fluid {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&.container-100pr #wrapper {
		width: 95%;
		.container,
		.container-fluid {
			width: 100%;
		}
	}
	
	&.container-shadow #wrapper {
		.box-shadow(0 0 25px 0 #777);
	}

	.header,
	section,
	.footer {
		background-color: @white-base;
	}
	.header {
		.navbar-default {
			background-color: @white-base;
			border-color: @white-base;
			padding: 0;

			.navbar-nav {
				> li {
					> a {
						padding: 30px 5px;
					}	
				}
			}
			.navbar-collapse {
				background-color: @white-base;
				border-color: @white-base;
				margin-right: 30px;
			}
		}
		#header-logo {
			margin: 0;
			padding-top: 27px;
		}
	}
}

@media only screen and (max-width : 991px) {
	.boxed-layout {
		padding-top: 15px;
		.header {
			.navbar-default {
				.navbar-nav {
					> li {
						> a {
							padding: 10px;
						}
					}
				}
				.navbar-collapse {
					margin-right: 15px;
				}
			}
		}
		.navbar-header {
			padding: 15px 0;
		}
		.navbar-collapse {
			.navbar-nav {
				li {
					a {
						.caret {
							margin-right: 0;
						}
					}	
				}
			}
		}
	}
}