@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        Imfundo
Version:        1.0
Created Date:   01.01.2018
Primary use:    Education & Courses HTML5 Template - Imfundo
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-Imfundo/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
@import "../css/elegant-icons.css";
@import "../fonts/icomoon/style.css";
@import "../fonts/linear-font-icon/style.css";
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/utility-classes.css";
//@import "../css/flaticon-set-law.css";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Montserrat:200,300,400,500,600,700,800,900');

// Initialize Variables
@import "less-Imfundo/variables.less";
@import "less-Imfundo/mixins.less";

// Typography
@import "less-Imfundo/typography.less";

// Common Styles
@import "less-Imfundo/common.less";
@import "less-Imfundo/extra.less";
@import "less-Imfundo/overlay.less";

// Header
@import "less-Imfundo/header.less";

// Nav
@import "less-Imfundo/nav.less";

// Content Blocks
@import "less-Imfundo/topbar.less";
@import "less-Imfundo/inner-header-title.less";
@import "less-Imfundo/vertical-nav.less";
@import "less-Imfundo/menu-full-page.less";
@import "less-Imfundo/boxed-layout.less";
@import "less-Imfundo/form.less";
@import "less-Imfundo/side-push-panel.less";
@import "less-Imfundo/box-hover-effect.less";
@import "less-Imfundo/gallery-isotope.less";
@import "less-Imfundo/home.less";
@import "less-Imfundo/contact.less";
@import "less-Imfundo/job.less";
@import "less-Imfundo/event.less";
@import "less-Imfundo/shop.less";
@import "less-Imfundo/blog.less";

// Shortcodes
@import "less-Imfundo/shortcodes.less";


// Widgets
@import "less-Imfundo/widgets.less";



// Widgets
@import "less-Imfundo/custom-theme/loader.less";

// Footer
@import "less-Imfundo/footer.less";